import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SearchControl } from './search-control';

type Props = {
	children: React.ReactElement;
};

export const RefinementBar = ({ children }: Props) => {
	return <Box xcss={wrapperStyles}>{children}</Box>;
};

const wrapperStyles = xcss({
	marginBottom: 'space.200',
});
