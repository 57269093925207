import React from 'react';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import { SearchField } from '@atlassian/jira-searchfield/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type SearchControlProps = {
	name: string;
	value: string;
	onChange: (value: string) => void;
	placeholderAlwaysVisible?: boolean;
	placeholder?: string;
	isCompact?: boolean;
};

export const SearchControl = ({
	name,
	value,
	onChange,
	placeholder,
	placeholderAlwaysVisible,
}: SearchControlProps) => {
	const [onInputChange] = useDebouncedCallback((newValue) => {
		onChange(newValue);
	}, 500);

	return (
		<SearchField
			id={name}
			shouldFitContainerWidth
			value={value}
			onChange={onInputChange}
			isAutocompleteDisabled
			placeholderAlwaysVisible={placeholderAlwaysVisible}
			placeholder={placeholder}
			showIconBeforeInput={isVisualRefreshEnabled()}
		/>
	);
};
