import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/ui/profilecard/async.tsx';
import type { userProfile_directoryBaseV3$key } from '@atlassian/jira-relay/src/__generated__/userProfile_directoryBaseV3.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

interface Props {
	dataRef: userProfile_directoryBaseV3$key;
}

const UserProfile = ({ dataRef }: Props) => {
	const {
		accountId,
		name: displayName,
		picture: avatarUrl,
	} = useFragment<userProfile_directoryBaseV3$key>(
		graphql`
			fragment userProfile_directoryBaseV3 on User {
				accountId
				name
				picture
			}
		`,
		dataRef,
	);

	const props = fg('jfp-a11y-team_directories_user-profile-name')
		? { accountId, prepopulatedData: { fullName: displayName } }
		: { accountId };

	return isVisualRefreshEnabled() ? (
		<AsyncProfileCardNext {...props}>
			<Box xcss={containerStyles} data-testId={`directory-user-profile-${accountId}`}>
				<Box xcss={avatarContainerStyles}>
					<Avatar src={avatarUrl} size="small" appearance="circle" />
				</Box>
				<Box xcss={textStyles}>{displayName}</Box>
			</Box>
		</AsyncProfileCardNext>
	) : (
		<AsyncProfileCardNext {...props}>
			<ContentContainer data-testId={`directory-user-profile-${accountId}`}>
				<AvatarContainer>
					<Avatar src={avatarUrl} size="small" appearance="circle" />
				</AvatarContainer>
				<TextContainer>{displayName}</TextContainer>
			</ContentContainer>
		</AsyncProfileCardNext>
	);
};

const UserProfileMemoized = memo(UserProfile);

export { UserProfileMemoized as UserProfile };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	paddingRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 0,
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	wordWrap: 'break-word',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
});

const avatarContainerStyles = xcss({
	paddingRight: 'space.100',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 0,
	flex: '0 0 auto',
});

const textStyles = xcss({
	display: 'contents',
	wordWrap: 'break-word',
	overflow: 'hidden',
	color: 'color.text.subtle',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});
